import React, { useEffect, useState } from 'react'
import loadable from "@loadable/component"
import APIS from '../../contants/EndPoints';
import usePostHook from '../../customHooks/usePostHook';
import useDeleteHook from '../../customHooks/useDeleteHook';
import useGetHook from '../../customHooks/useGetHook';
import usePatchHook from '../../customHooks/usePatchHook';
import moment from 'moment';

const ScreenComponent = loadable(() => import("../../components/Screen/screen"));

const Screen = () => {
  // const { id } = useParams()
  const [isAddPopup, setIsAddPopup] = useState(false)
  const [isEditPopup, setIsEditPopup] = useState(false)
  const [selectedObject, setSelectedObject] = useState(null)
  const [isOpenAction, setIsOpenAction] = useState(false)
  const [isSlideOpen, setIsSlideOpen] = useState(false)


  //screen add
  const { isPostLoading, mutateAsync: postMutateAsync, postData } = usePostHook({ queryKey: ["screen"], navigateURL: null })
  const handleCreateScreen = async (values: any) => {
    const url = APIS.SCREEN
    const formData = {
      name: values?.name
    }
    try {
      const res = await postMutateAsync({ url, formData })
      setIsAddPopup(false)
    } catch (err) {
      console.log(err);
    }
  }
  //Screen Delete
  const { mutateAsync: deleteMutateAsync } = useDeleteHook({ queryKey: ["screen"] })
  const handleDelete = async (setOpenPopup: any, pk: string) => {
    const url = `${APIS.SCREEN}${pk}/`
    try {
      const res = await deleteMutateAsync({ url })
      console.log(res, "ii")
      setOpenPopup(false)
    } catch (err) {
      console.log(err);
    }
  }

  //Screen Update
  const { mutateAsync } = usePatchHook({ queryKey: ["screen"], navigateURL: null })

  const handleUpdateScreen = async (values: any, setError: any) => {
    const url = `${APIS.SCREEN}${selectedObject}/`
    let formData = values

    console.log(values)
    try {
      const res = await mutateAsync({ url, formData, setError })
      setIsEditPopup(false)
    } catch (err) {
      console.log(err);
    }
  }
  //Mac Reset
  const { mutateAsync: patchMutateAsync } = usePostHook({ queryKey: ["screen"], navigateURL: null })
  const handleChangeMac = async (values: any, setError: any) => {
    const url = `${APIS.SCREEN}${selectedObject}/reset-device/`
    let formData = values

    console.log(values)
    try {
      const res = await patchMutateAsync({ url, formData, setError })
      setIsOpenAction(false)
    } catch (err) {
      console.log(err);
    }
  }
  //Get slide
  const { data: slideData, isLoading } = useGetHook({
    queryKey: ['slide'],
    url: `${APIS.SLIDES}`,
    params: {}
  });
  const { data: screenData, isLoading: isScreenLoading } = useGetHook({
    queryKey: ['screen'],
    url: `${APIS.SCREEN}${selectedObject}`,
    params: {}
  });
  console.log(screenData, "gbtoiig")

  //Get Handle LAzy Screen
  const { data: serverData, isLoading: singleScreenDataLoading, refetch } = useGetHook({
    queryKey: ['screen'],
    url: `${APIS.SCREEN}${selectedObject}`,
    params: {},
    enabled: false
  });
  const handleLazyFetch = async () => {
    const res = await refetch()
    return res.data
  }
  //Add Slide in Screen
  const { mutateAsync: slideMutateAsync } = usePostHook({ queryKey: ["screen"], navigateURL: null })
  const handleAddSlide = async (values: any, setError: any, setIsSlideOpen: any) => {
    const url = `${APIS.SCREEN}${selectedObject}/assign-slide/`
    let formData = values

    try {
      const res = await slideMutateAsync({ url, formData, setError })
      if (res?.status == 200) {
        setIsSlideOpen(false)
      }
    } catch (err) {
      console.log(err);
    }
  }
  //Delete Slide From screen
  const { mutateAsync: deleteSlideMutateAsync } = useDeleteHook({ queryKey: [] })
  const handleSlideDelete = async (setOpenPopup: any) => {
    const url = `${APIS.ASSIGN_SLIDE}${selectedObject}/`
    try {
      const res = await deleteSlideMutateAsync({ url })
      console.log(res, "ii")
      setOpenPopup(false)
    } catch (err) {
      console.log(err);
    }
  }
  //Play Button
  const { isPostLoading: isPlayLoading, mutateAsync: playButtonMutateAsync, postData: playPostData } = usePostHook({ queryKey: ["screen"], navigateURL: null })
  const handleCreatePlayButton = async (values: any) => {
    const url = `${APIS.SCREEN}play-slide/`
    const formData = {
      screen: values,

    }
    console.log(formData)
    try {
      const res = await playButtonMutateAsync({ url, formData })
    } catch (err) {
      console.log(err);
    }
  }
  //Play Button
  const { isPostLoading: isPlaySlideLoading, mutateAsync: playSlideButtonMutateAsync, postData: playSlidePostData } = usePostHook({ queryKey: ["screen"], navigateURL: null })
  const handleCreatePlayButtonSlide = async (values: any, pk: string) => {
    const url = `${APIS.SCREEN}play-slide/`
    const formData = {
      screen: values?.screen,
      slide: values?.slide

    }
    try {
      const res = await playButtonMutateAsync({ url, formData })
    } catch (err) {
      console.log(err);
    }
  }
  //Status Update
  const { mutateAsync: patchStatusMutateAsync } = usePatchHook({ queryKey: ["screen"], navigateURL: null })

  const handleIsActive = async (e: any, id: any) => {

    const payload = {
      is_active: e.target.checked
    };

    const url = `${APIS.SCREEN}${id}/`;
    const formData = payload
    try {
      await patchStatusMutateAsync({ url, formData });

    } catch (e) {
      console.log(e)
    }
  }

  return (
    <>
      <ScreenComponent
        handleCreateScreen={handleCreateScreen}
        handleDelete={handleDelete}
        isAddPopup={isAddPopup}
        screenData={screenData}
        setIsAddPopup={setIsAddPopup}
        handleUpdateScreen={handleUpdateScreen}
        selectedObject={selectedObject}
        setSelectedObject={setSelectedObject}
        isEditPopup={isEditPopup}
        setIsEditPopup={setIsEditPopup}
        serverData={serverData}
        handleLazyFetch={handleLazyFetch}
        handleChangeMac={handleChangeMac}
        setIsOpenAction={setIsOpenAction}
        isOpenAction={isOpenAction}
        isSlideOpen={isSlideOpen}
        setIsSlideOpen={setIsSlideOpen}
        slideData={slideData}
        handleAddSlide={handleAddSlide}
        handleSlideDelete={handleSlideDelete}
        handleCreatePlayButton={handleCreatePlayButton}
        handleCreatePlayButtonSlide={handleCreatePlayButtonSlide}
        handleIsActive={handleIsActive}
        singleScreenDataLoading={singleScreenDataLoading}
      />
    </>


  )
}

export default Screen