import React, { useState } from 'react'
import loadable from "@loadable/component"
import usePostHook from '../../customHooks/usePostHook';
import APIS from '../../contants/EndPoints';
import useDeleteHook from '../../customHooks/useDeleteHook';
import usePatchHook from '../../customHooks/usePatchHook';
import useGetHook from '../../customHooks/useGetHook';
import { accessToken } from '../../helpers/sessionKey';
import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';


const SlidesComponent = loadable(() => import("../../components/Slides/slides"));
const Slides = () => {
  const queryClient = useQueryClient()

  const [isAddPopup, setIsAddPopup] = useState(false)
  const [isEditPopup, setIsEditPopup] = useState(false)
  const [selectedObject, setSelectedObject] = useState(null)
  const [fileUploadProgress, setFileUploadProgress] = useState(0)

  const { isPostLoading, mutateAsync: postMutateAsync, postData } = usePostHook({ queryKey: ["slides"], navigateURL: null })
  const handleCreateSlides = async (values: any) => {
    const url = APIS.SLIDES
    const formData = values

    try {
      const res = await postMutateAsync({ url, formData })
      setIsAddPopup(false)
    } catch (err) {
      console.log(err);
    }
  }
  const { mutateAsync: deleteMutateAsync } = useDeleteHook({ queryKey: ["slides"] })

  const handleDelete = async (setOpenPopup: any, pk: string) => {
    const url = `${APIS.SLIDES}${pk}/`
    try {
      const res = await deleteMutateAsync({ url })
      console.log(res, "ii")
      setOpenPopup(false)
    } catch (err) {
      console.log(err);
    }
  }
  const { mutateAsync } = usePatchHook({ queryKey: ["slides"], navigateURL: null })

  const handleUpdateSlides = async (values: any, setError: any) => {
    const url = `${APIS.SLIDES}${selectedObject}/`
    let formData = values
    try {
      const res = await mutateAsync({ url, formData, setError })
      // navigate(`/edit-author/${res.data.id}`)
      setIsEditPopup(false)
    } catch (err) {
      console.log(err);
    }
  }
  const { refetch } = useGetHook({
    queryKey: [`slide-${selectedObject}`],
    url: `${APIS.SLIDES}${selectedObject}`,
    params: {},
    enabled: false
  });

  const handleLazyFetch = async () => {
    const res = await refetch()
    return res.data
  }

  const { data: singleSlidedata, isLoading: singleSlideDataLoading, refetch: refetchImages } = useGetHook({
    queryKey: [`${selectedObject}`],
    url: `${APIS.IMAGE}`,
    params: {
      slides: selectedObject
    }
  });


  const imageUpdateHandler = async (imgfile, imgName, selectedObject) => {
    var xhr = new XMLHttpRequest();
    // Progress event listener
    xhr.upload.addEventListener('progress', function (event) {
      if (event.lengthComputable) {
        var progress = (event.loaded / event.total) * 100;
        setFileUploadProgress(progress)
      }
    });
    // Ready state change event listener
    xhr.onreadystatechange = async function () {
      if (xhr.readyState === 4) {
        if (xhr.status === 200 || xhr.status === 201) {
          toast.success("File uploaded successfully")
          setFileUploadProgress(0)
          await refetchImages()
          await queryClient.invalidateQueries({ queryKey: ['slides'] })
        } else {
          setFileUploadProgress(0)
          toast.error("Failed to upload file")
        }
      }
    };
    // Set up the request
    xhr.open('POST', 'https://digitalsignage.dibsolutions.com.au/api/images/', true);  // Replace '/upload' with your server endpoint
    xhr.setRequestHeader('Authorization', `JWT ${accessToken()}`);
    const formData = new FormData();
    formData.append("name", `${imgName}`);
    formData.append("slides", `${selectedObject}`);
    formData.append("image", imgfile);
    xhr.send(formData);
  };

  const { data: serverData, isLoading } = useGetHook({
    queryKey: ["slide"],
    url: APIS.SLIDES,
    params: {

    }
  });

  // delete image
  const { mutateAsync: deleteSessionMutate } = useDeleteHook({
    queryKey: `singleSlidedata`,
  });

  const handleDeleteImage = async (id: any) => {
    const url = `${APIS.IMAGE}${id}/`;

    try {
      await deleteSessionMutate({ url });
    } catch (e) {
      console.log(e);
    }
  };

  const { mutateAsync: updateIntervalAsync } = usePatchHook({ queryKey: ["slides"], navigateURL: null })

  const handleUpdateInterval = async (values: any, setError: any,setIsAddImagePopup:any) => {
    const url = `${APIS.SLIDES}${selectedObject}/`
    let formData = values
    try {
      const res = await updateIntervalAsync({ url, formData, setError })
      // navigate(`/edit-author/${res.data.id}`)
      setIsAddImagePopup(false)
    } catch (err) {
      console.log(err);
    }
  }


  return (
    <SlidesComponent
      serverData={serverData}
      handleCreateSlides={handleCreateSlides}
      handleDelete={handleDelete}
      isAddPopup={isAddPopup}
      setIsAddPopup={setIsAddPopup}
      selectedObject={selectedObject}
      setSelectedObject={setSelectedObject}
      isEditPopup={isEditPopup}
      setIsEditPopup={setIsEditPopup}
      handleUpdateSlides={handleUpdateSlides}
      handleLazyFetch={handleLazyFetch}
      imageUpdateHandler={imageUpdateHandler}
      singleSlidedata={singleSlidedata}
      singleSlideDataLoading={singleSlideDataLoading}
      handleDeleteImage={handleDeleteImage}
      fileUploadProgress={fileUploadProgress}
      handleUpdateInterval={handleUpdateInterval}
    />
  )
}

export default Slides