const APIS = {
  // Pages
  SCREEN: `/screen/`,
  SLIDES: `/slides/`,

  ASSIGN_SLIDE: `/assign-slide/`,
  PLAY_SLIDE: `/play-slide/`,
  IMAGE: `/images/`,

};

export default APIS;
